<template>
  <div class="edit-broadcast">
    <div class="row">
      <div class="col-12 edit-broadcast-form">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingBroadcast">
              <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="broadcastForm"
              >
                <form
                  v-if="broadcast"
                  class="authentication-form"
                  @submit.prevent="handleSubmit(onUpdateBroadcast)"
                >
                  <div v-if="!broadcastEditable" class="d-flex justify-content-between align-items-center">
                    <span
                      class="text-danger d-block mb-2 font-weight-bold"
                    >You can't edit this broadcast, It's already processed!</span>
                    <router-link :to="{ name: 'business.broadcasts.duplicate', params: { id: broadcast.id } }" class="btn btn-primary">
                      <i class="uil uil-upload-alt"></i> Duplicate
                    </router-link>
                  </div>
                  <TextInput v-model="broadcast.name" label="Name" rules="required" name="name" :readonly="!broadcastEditable"/>
                  <ValidationProvider
                    v-slot="{ failed, errors }"
                    name="keyword"
                  >
                    <div class="form-group">
                      <label>Keywords</label>
                      <multiselect
                        v-model="broadcast.keywords"
                        :options="keywords"
                        :multiple="true"
                        :class="{ 'is-invalid': failed }"
                        placeholder="Select keywords"
                        :disabled="!broadcastEditable"
                      ></multiselect>
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                      <div v-if="broadcastEditable" class="mt-2">
                        <b-spinner v-if="loadingCredit" small></b-spinner>
                        <div v-if="!loadingCredit && messageCredit">
                          <div v-if="isEmail">
                            <div v-if="messageCredit && messageCredit.credit">
                              <span class="text-success">{{ messageCredit.credit }} contacts are available.<br/></span>
                            </div>
                            <div v-else>
                              <span class="text-danger">ALERT: No contacts are available for this broadcast!</span>
                            </div>
                          </div>
                          <div v-else>
                            <div v-if="msgCreditUsed">
                              <span
                                v-if="messageCredit.creditAvailable" 
                                :class="{ 
                                  'text-danger': messageCredit.creditAvailable < msgCreditUsed,
                                  'text-success': messageCredit.creditAvailable >= msgCreditUsed,
                                }"
                              >Credits Available: {{ messageCredit.creditAvailable }}<br/></span>
                              <span>Credit Required For This Broadcast: {{ msgCreditUsed }}<br/></span>
                              <span v-if="messageCredit.creditAvailable && messageCredit.creditAvailable < msgCreditUsed" class="text-danger">ALERT: There are not enough credits available to run a broadcast. Contact <a :href="'mailto:' + agencyEmail" target="_blank"><b class="text-danger">{{ agencyEmail }}</b></a> to upgrade.</span>
                            </div>
                            <div v-else>
                              <span class="text-danger">ALERT: No contacts are available for this broadcast!</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Exclude these people from the broadcast</label>
                      <multiselect
                        v-model="broadcast.exclude_keywords"
                        :options="listKeywords"
                        :multiple="true"
                        :class="{ 'is-invalid': failed }"
                        placeholder="Select keywords"
                        label="name"
                        track-by="id"
                        :disabled="!broadcastEditable"
                      ></multiselect>
                    </div>
                  </ValidationProvider>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <b-input-group-append>
                          <b-button style="border-radius:0.3rem 0 0 0.3rem;"
                            :disabled="!broadcastEditable"
                            :variant="broadcast.send_type === 'now' ? 'primary' : 'outline-dark'"
                              @click="chooseSentAt('now')">Send Now</b-button>
                          <b-button style="border-left: none;border-radius:0 0.3rem 0.3rem 0;"
                            :disabled="!broadcastEditable"
                            :variant="broadcast.send_type === 'later' ? 'primary' : 'outline-dark'"
                            @click="chooseSentAt('later')">Send Later</b-button>
                        </b-input-group-append>
                      </div>
                      <div v-if="broadcast.send_type === 'later'" class="col-md-12 mt-4">
                        <div class="row">
                          <div class="col-6">
                            <flat-pickr
                              v-model="broadcast.date"
                              :config="dateTimePicker"
                              class="form-control"
                              placeholder="Pick a date"
                            ></flat-pickr>
                          </div>
                          <div class="col-6">
                            <TimePicker v-model="broadcast.time" :night-off="true" />
                          </div>
                          <div class="col-md-12">
                          <SelectInput
                            v-model="broadcast.timezone"
                            :options="timezones"
                            label=""
                          />
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div v-if="isEmail">
                    <div v-if="showEmailOptions" class="form-group">
                      <label>Email account <span v-b-tooltip.hover class="cursor-pointer"
                          title="You can leave this blank to use system default email client."><i
                            class="uil uil-question-circle"></i></span></label>
                      <multiselect v-model="broadcast.options.integration_user" :options="emailOptions" :multiple="false" :allow-empty="false"
                        track-by="id" placeholder="Select Account" label="name"></multiselect>
                    </div>
                    <div v-if="showMergeField" class="form-group">
                      <MergeFieldsGroup :text-field="$refs.message" :show-redemption="true" :hide-expire-date="true" @selected="appendField" />
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <TextInput v-model="broadcast.options.from_name" label="From Name" name="fromName"></TextInput>
                      </div>
                      <div class="col-md-6">
                        <TextInput v-model="broadcast.options.from_email" type="email" label="From Email" name="fromEmail"></TextInput>
                      </div>
                    </div>
                    <TextInput v-model="broadcast.subject" label="Subject" name="subject" rules="required"></TextInput>
                    <TextEditorAction v-model="broadcast.message" :object="broadcast" rules="required"></TextEditorAction>
                  </div>
                  <div v-else>
                    <div class="form-group">
                      <div v-if="showMergeField" class="mb-2">
                        <MergeFieldsGroup :text-field="$refs.message" :show-redemption="true" :hide-expire-date="true" @selected="appendField" />
                      </div>
                      <TextAreaAction ref="message" v-model="broadcast.message" label="Message"
                        :sms-length="true" rules="required" :object="broadcast" :add-optout="true" name="message" />
                    </div>
                  </div>

                  <div class="mt-5 d-flex justify-content-between">
                    <b-form-group v-if="showMergeField">
                      <b-button
                        variant="primary"
                        :disabled="invalid"
                        type="button"
                        class="mr-2"
                        @click="onClickTest"
                      >
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else><i class="uil uil-text"></i> Send Test {{ isEmail ? 'Email' : 'Message' }}</span>
                      </b-button>
                      <b-button
                        v-if="broadcastEditable"
                        variant="primary"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="button"
                        class="mr-3"
                        @click="handleSaveAsDraft"
                      >
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else><i class="uil uil-document"></i> Save As Draft</span>
                      </b-button>
                      <b-button
                        variant="primary"
                        :disabled="loadingUpdate || !newBroadcastValid || (invalid && touched)"
                        type="submit"
                      >
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else><i class="uil uil-upload-alt"></i> Send</span>
                      </b-button>
                    </b-form-group>
                    <b-form-group>
                      <b-button
                        variant="warning"
                        @click="onBack"
                      >
                        <span><i class="uil uil-arrow-left"></i> Back</span>
                      </b-button>
                    </b-form-group>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showRedemptions" class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <h5 class="card-title mt-0 mb-0 header-title">Redemptions</h5>
              </div>
              <div class="col-md-9 d-flex justify-content-end">
                <input v-model="datatable.queries.search" type="text" class="form-control form-control-sm" placeholder="Search..." style="width: 200px;">
                <select
                  v-model="datatable.queries.status"
                  class="form-control ml-2 form-control-sm"
                  style="width: 200px;"
                >
                  <option value="">Status</option>
                  <option value="redeemed">Redeemed</option>
                  <option value="active">Active</option>
                  <option value="expired">Expired</option>
                </select>
                <button
                  class="ml-1 btn btn-primary btn-sm"
                  @click="getRedemptions"
                >
                  <i class="uil uil-search-alt"></i>
                </button>
              </div>
            </div>
            <div v-if="!loadingRedemptions" class="table-responsive mt-4">
              <b-table
                :items="redemptions.data"
                :fields="datatable.columns"
                :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc"
                :sort-direction="datatable.queries.sort_direction"
                :per-page="datatable.queries.per_page"
                :no-local-sorting="true"
                @sort-changed="sortChanged"
              >
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | datetime(`${$store.getters['auth/dateFormat']} HH:mm:ss`) }}
                </template>
                <template v-slot:cell(redeemd_at)="data">
                  {{ data.item.redeemd_at | datetime(`${$store.getters['auth/dateFormat']} HH:mm:ss`) }}
                </template>
                <template v-slot:cell(total_amount)="data">
                  {{ data.item.total_amount | currency }}
                </template>
                <template v-slot:cell(status)="data">
                  <span
                    v-if="data.item.status === 'redeemed'"
                    class="badge badge-soft-success"
                    >Redeemed</span
                  >
                  <span
                    v-else-if="data.item.status === 'expired'"
                    class="badge badge-soft-danger"
                    >Expired
                  </span>
                  <span v-else class="badge badge-soft-warning"
                    >Active</span
                  >
                </template>
                <template v-slot:cell(actions)="data">
                  <b-dropdown
                    v-if="data.item.status === 'active'"
                    variant="black"
                    toggle-class="arrow-none text-muted pr-0"
                    right
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-v"></i>
                    </template>

                    <b-dropdown-item @click="redeem(data.item)">
                      <i class="uil uil-atm-card mr-2"></i> Redeem
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <div class="mt-4">
                <div class="float-left mt-2">
                  Total {{ redemptions.meta.total }} redemptions
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="datatable.queries.page"
                      :total-rows="redemptions.meta.total"
                      :per-page="datatable.queries.per_page"
                      @change="onPageChanged"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showRedeemModal"
      :title="
        `Redeem #${
          selectedRedemption ? selectedRedemption.redemption_code : ''
        }`
      "
    >
      <div v-if="selectedRedemption">
        <TextInput
          v-model="selectedRedemption.total_amount"
          name="amount"
          label="Total amount spent"
          type="number"
          rules="required"
        />
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showRedeemModal = false"
          >Cancel</b-button
        >
        <b-button
          :disabled="!selectedRedemption.total_amount || loadingRedeem"
          variant="success"
          @click="handleRedeem"
        >
          <b-spinner v-if="loadingRedeem" small />
          <span v-else>Redeem</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="isOpenModalTest" title="Test Broadcast">
      <template v-if="isEmail">
        <p>Please Input Email Address to Send Test Broadcast.</p>
        <TextInput v-model="testNumber" name="testEmail" rules="required|email" label="Email" />
      </template>
      <template v-else>
        <p>Please Input Phone Number to Send Test Broadcast.</p>
        <PhoneInput
          v-model="testNumber"
          name="Phone Number"
          label="Phone Number"
          rules="required|phone"
        />
      </template>
      <template v-slot:modal-footer>
        <b-button
          variant="light"
          class="mr-2"
          @click="isOpenModalTest = false"
          >Close</b-button
        >
        <b-button
          :disabled="loadingUpdate || !testNumber"
          variant="primary"
          @click="handleTest"
          >
            <b-spinner v-if="loadingUpdate" class="align-middle" small></b-spinner>
            <span v-else>Send</span>
          </b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="isOpenModalConfirm" title="Confirmation">
      <p>When sending out a broadcast {{ isEmail ? 'emails' : 'messages' }}, it is important that you send these messages during the day between <b class="text-primary">8am</b> and <b class="text-primary">9pm</b> during the recipients timezone. <br />If you aren't aware of their time zone, we suggest sending it in the middle of the day.</p>
      <p>Click the button below to confirm you want to send or schedule this messages at this time.</p>
      <template v-slot:modal-footer>
        <b-button
          variant="light"
          class="mr-2"
          @click="isOpenModalConfirm = false"
          >Close</b-button
        >
        <b-button
          :disabled="loadingUpdate"
          variant="primary"
          @click="updateBroadcast"
          >
            <b-spinner v-if="loadingUpdate" class="align-middle" small></b-spinner>
            <span v-else>{{ confirmLabel }}</span>
          </b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import moment from 'moment-timezone'

export default {
  components: {
    Multiselect,
  },

  props: {
    object: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      keywords: [],
      loadingBroadcast: false,
      broadcast: null,
      loadingUpdate: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
      selectedRedemption: null,
      showRedeemModal: false,
      loadingRedeem: false,
      dateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
      },
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
      loadingRedemptions: false,
      redemptions: { data: [], meta: { total: 0 }},
      datatable: {
        columns: [
          { key: 'phone', label: 'Contact Number' },
          { key: 'keyword', label: 'Keyword' },
          { key: 'redemption_code', label: 'Redeem code' },
          { key: 'created_at', label: 'Request date', sortable: true },
          { key: 'redeemd_at', label: 'Redeem date', sortable: true },
          { key: 'total_amount', label: 'Amount', sortable: true },
          { key: 'status', label: 'Status' },
          { key: 'actions' },
        ],
        queries: {
          search: '',
          per_page: 10,
          page: 1,
          sort_by: '',
          sort_desc: true,
          status: '',
        }
      },
      loadingCredit: false,
      messageCredit: null,
      testNumber: null,
      isOpenModalTest: false,
      isOpenModalConfirm: false,
      listKeywords: [],
      emailOptions: [],
      constBroadcast: null,
    }
  },

  computed: {
    showMergeField() {
      return this.broadcast && (['scheduled', 'draft', 'paused'].includes(this.broadcast.status))
    },

    showEmailOptions() {
      return this.emailOptions && this.emailOptions.length > 1 
    },

    isEmail() {
      return this.broadcast && this.broadcast.provider === 'email'
    },

    confirmLabel() {
      return this.broadcast.send_type === "now" ? "Send Now" : "Schedule";
    },

    newBroadcastValid() {
      if (!this.msgCreditUsed) return false
      
      if (this.broadcast.send_type === 'now') {
        return !this.loadingUpdate;
      } else {
        return (
          this.broadcast.date &&
          this.broadcast.time &&
          !this.loadingUpdate
        );
      }
    },

    timezones() {
      return this.$store.getters['app/timezones']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    broadcastEditable() {
      return ['scheduled', 'draft', 'paused'].includes(this.broadcast.status)
    },

    agencyEmail() {
      return this.user && this.user.business.agency.email;
    },

    msgCount() {
      if (this.broadcast.images && this.broadcast.images.length)
        return 3;
      return Math.ceil((this.broadcast.message.length + 21) / 160)
    },

    msgCreditUsed() {
      if (this.messageCredit && this.messageCredit.credit)
        return this.messageCredit.credit * this.msgCount
      return 0
    },

    showRedemptions() {
      return this.broadcast && !['scheduled', 'draft'].includes(this.broadcast.status)
    },
  },

  watch: {
    'broadcast.keywords': function(val) {
      this.getMessageCredit()
    },
    'broadcast.exclude_keywords': function(val) {
      this.getMessageCredit()
    },
  },

  mounted() {
    this.getRedemptions()
    this.getKeywords()
    this.getEmailOptions()
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getRedemptions()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getRedemptions()
    },

    onBack() {
      this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider} })
    },

    getEmailOptions() {
      this.$store.dispatch('integration/selectOptions')
        .then(options => {
          this.emailOptions = options
        })
        .catch(() => { })
    },

    onUpdateBroadcast() {
      this.isOpenModalConfirm = true
    },

    updateBroadcast() {
      this.loadingUpdate = true

      if (this.broadcast.send_type === 'later') {
        this.broadcast.scheduled_at = moment.tz(this.broadcast.date + ' ' + this.broadcast.time, "YYYY-MM-DD hh:mm A", this.broadcast.timezone || this.user.business.timezone).utc().format();
      } else {
        delete this.broadcast.scheduled_at 
      }
      
      this.$store
        .dispatch('broadcast/update', this.broadcast)
        .then(() => {
          this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
          this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider} })
          this.loadingUpdate = false
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords.map(c => c.name)
          this.listKeywords = keywords
          this.getBroadcast()
        })
        .catch(() => {
        })
    },

    getBroadcast() {
      this.broadcast = JSON.parse(JSON.stringify(this.object))
      this.constBroadcast = JSON.parse(JSON.stringify(this.object))
    },

    getRedemptions() {
      this.loadingRedemptions = true

      this.$store
        .dispatch('broadcast/redemptions', { broadcastId: this.$route.params.id, queries: this.datatable.queries })
          .then((redemptions) => {
            this.redemptions = redemptions
            this.loadingRedemptions = false
          })
          .catch(() => {
            this.loadingRedemptions = false
          })
    },

    redeem(redemption) {
      this.selectedRedemption = JSON.parse(JSON.stringify(redemption))
      this.showRedeemModal = true
    },

    handleRedeem() {
      this.loadingRedeem = true

      this.$store
        .dispatch('coupon/redeem', this.selectedRedemption)
        .then(() => {
          this.showRedeemModal = false
          this.loadingRedeem = false
          this.getRedemptions()
        })
        .catch(() => {
          this.loadingRedeem = false
        })
    },

    chooseSentAt(sendType) {
      this.broadcast.send_type = sendType
    },

    getMessageCredit() {
      this.loadingCredit = true

      const param = {
        keywords: this.broadcast.keywords,
        excludes: this.broadcast.exclude_keywords,
        provider: this.broadcast.provider,
        isResend: this.isResend,
        resendType: this.resendType,
        id: this.broadcast.id,
      }

      this.$store
        .dispatch('broadcast/messageCredit', param)
        .then((messageCredit) => {
          this.messageCredit = messageCredit
          this.loadingCredit = false
        })
        .catch(() => {
          this.loadingCredit = false
        })
    },

    handleTimeClose(event) {
      let time = moment(`2021-01-01 ${this.broadcast.time}`)
      time.minute(time.minute() - time.minute() % 5)

      this.broadcast.time = time.format('hh:mm A')
    },

    handleSaveAsDraft() {
      this.broadcast.save_draft = true
      this.updateBroadcast()
    },

    onClickTest() {
      this.testNumber = null
      this.isOpenModalTest = true
    },
    handleTest() {
      this.loadingUpdate = true;
      const param = Vue.util.extend({}, this.broadcast);
      param.number = this.testNumber
      this.$store.dispatch('broadcast/sentTest', param).then((res) => {
        this.isOpenModalTest = false;
        this.loadingUpdate = false;
      })
      .catch(() => {
        this.loadingUpdate = false;
      });;
    },
    appendField(field) {
      document.execCommand('insertText', false, field)
    },

    beforeRouteLeave (to, from , next) {
      let diff = this.difference(this.broadcast, this.constBroadcast)

      if (Object.keys(diff).length) {
        const answer = window.confirm('Are you sure you want to leave without saving?')
        if (answer) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    }
  },
}
</script>

<style lang="scss">
.edit-broadcast {
  .edit-broadcast-form {
    max-width: 630px;
  }
  .flatpickr-input {
    background: #FFF !important;
  }
}
</style>